var products = [
  {
    File: 'product_BMO.webp',
    Name: 'BMO B-BOY',
    Description: 'Real life break dancing game boy!',
    Price: '3338.20',
  },

  {
    File: 'product_LADY.webp',
    Name: 'Mona Lisa',
    Description: 'Real! Signed by Mona Lisa herself, according to seller.',
    Price: '842.00',
  },
  {
    File: 'product_Cyborg_03.webp',
    Name: 'Cyborg skull',
    Description: 'Teeth are in good condition.',
    Price: '2919.97',
  },

  {
    File: 'A-Maneki-Neko.webp',
    Name: 'Goodluck cat',
    Description: 'Side effects include midnight Catnip cravings.',
    Price: '32.00',
  },
  {
    File: 'synth.webp',
    Name: 'Casiotone for the miserable alone',
    Description: 'Perfect for people with broken hearts or old guys with big mustaches.',
    Price: '149.47',
  },
  {
    File: 'product_guitar.webp',
    Name: 'Robert Johnson Guitar',
    Description: 'Used by famous blues musician  Robert Johnson. Not crossroads-compatible.',
    Price: '666.00',
  },

];


export default products;

