const products = [
  {
    File: 'cerimonial_knife.webp',
    Name: 'Ceremonial knife',
    Description: 'Perfect for human sacrifices!',
    Price: '47.00',
  },
  {
    File: 'CRT_TV.webp',
    Name: 'CRT TV',
    Description: 'Can channel the spirit realm.',
    Price: '5.00',
  },
  {
    File: 'product_STRO_087.webp',
    Name: 'Stereo 087',
    Description: 'Can only play cuntry.',
    Price: '100000.00',
  },
  {
    File: 'red-phone.webp',
    Name: 'Red phone',
    Description: 'Calls the Powerpuff Girls.',
    Price: '30.78',
  },
  {
    File: 'product_NUK.webp',
    Name: 'Fat-Man',
    Description: 'It might wake up the neighbors...',
    Price: '1945.50',
  },
  {
    File: 'gun_1482610_1920.webp',
    Name: 'Argument winner',
    Description: "Can’t beat ‘em, eat ‘em",
    Price: '58.59',
  },

]

export default products;