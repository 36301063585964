import './roadSide.css'

const roadSide = () => {

  return (
    <>
      <div id="road-side">
        <div id="sidewalk-top">
          <a href="https://www.instagram.com/theholyrelapse/" target="_blank" rel="noopener noreferrer">
            <img id="speach" alt="come here finger gesture in a speach bubble" src="speach.gif" ></img>
          </a>

          <img src="cone.png" alt="traffic cone" className="cone"></img>

        </div>

        <div id="sidewalk-1" className="concrete"></div>
        <div id="sidewalk-2" className="concrete" >
          <a href="https://www.instagram.com/theholyrelapse/" target="_blank" rel="noopener noreferrer" >
            <img id="sewer" src="sewer.png" alt="Ninja turtule and IT the clown peeking from sidewalk drain"></img>

          </a>
        </div>
        <div id="asfault"></div>
      </div>



    </>
  )

}

export default roadSide;