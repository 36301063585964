const products = [
  {
    File: 'Chachapoyan.webp',
    Name: 'Chachapoyan',
    Description: ' Real gold!! Would make a great wedding ring.',
    Price: '7854.98 ',
  },
  {
    File: 'Grill_cdhr.webp',
    Name: 'Grill',
    Description: ' Good for fulfilling the superficial status quo.',
    Price: '468.88',
  },
  {
    File: 'Gill_011.webp',
    Name: 'Grillz',
    Description: 'Cut from the gums of a suburban soundcloud rapper.',
    Price: '500.00',
  },
  {
    File: 'tutankhamun.webp',
    Name: 'Tutankhamun',
    Description: 'Real gold!! Smells like mummy though...',
    Price: '896.58',
  },
  {
    File: 'grill_126.webp',
    Name: 'Grillz',
    Description: 'Made with blood diamonds.',
    Price: '1499.00',
  },
  {
    File: 'grill_053.webp',
    Name: 'Grill',
    Description: 'Real human teeth colored with gold spraypaint.',
    Price: '4.64',
  },

];


export default products