const characterInfo = [
  {
    Name: 'Jesus',
    Description: 'The fabled son of God who has chosen a... erm.... different path in life. We first meet him while he is on the lam from some shady individuals. Will he be able to squirm and con his way out of his own self-imposed destruction this time?',
    Age: '2022 yrs',
    Weight: '110 lbs',
    Row: 'one',
    File: 'Jesus.webp',
    Portrait: 'Jesus_portrait.jpg',
    Alt: 'Jesus Character inhaling deeply',
    Id: 'Jesus',
  },
  {
    Name: 'Plomo',
    Description: 'Aka “Lead”. One half of the team “Plomo y Plata”, hired muscle for a mysterious entity chasing down Jesus. As dumb as a box of rocks and quick to anger, Plomo strikes fear into anyone who crosses his ice cream-tattooed face and cold, dead lazy eyes.',
    Age: '65 yrs',
    Weight: '420 lbs',
    Row: 'two',
    File: 'Plomo.webp',
    Portrait: 'Plomo_portrait.jpg',
    Alt: 'Plomo character with evil smile',
    Id: 'Plomo'
  },
  {
    Name: 'Plata',
    Description: 'Aka “Money“. The other, more cunning half and de facto leader of Plomo y Plata. Obsessed with all material things and making that sweet, sweet green, there are no lows too low for him to stoop to, and he is willing to sacrifice even his closest associates for the sake of a quick pay day. This guy’s a real piece of shit.',
    Age: '69 yrs',
    Weight: '150 lbs',
    Row: 'one',
    File: 'Plata.webp',
    Portrait: 'Plata_portrait.jpg',
    Alt: 'Plata character with evil smile',
    Id: 'Plata'
  },
  {
    Name: 'Davey Jones',
    Description: 'The mysterious and unnerving owner of the seedy Pawn Shop which Jesus and the other denizens of the town often frequent. He’s willing to barter for just about anything as long as he can flip a profit and is known to acquire rare items from time to time. It’s even rumored that he owns the very heart of Satan itself, but no one has had the courage to confirm this',
    Age: 'unknown',
    Weight: 'unknown',
    Row: 'two',
    File: 'Davey_Jones.webp',
    Portrait: 'Davey_Jones_portrait.jpg',
    Alt: 'Pawn shop broker character with reading newsPaper',
    Id: 'Broker'
  },
  {
    Name: 'Crime Wave',
    Description: 'An inhuman entity from Tartarus known to inhabit the darkest corners of impoverished communities and neighborhoods of the land. Seemingly immortal and timeless, it has been known by many names in the past, such as the “cucuy” and “la llorona”. If you are looking for trouble, Crime Wave will find you.',
    Age: 'as old as time',
    Weight: '1000 suns',
    Row: 'one',
    File: 'Crime_Wave.webp',
    Portrait: 'Crime_Wave_portrait.jpg',
    Alt: 'Crime Wave character holding bag of drugs',
    Id: 'Crime-Wave'
  },
  {
    Name: 'Mauro de la Tierra',
    Description: 'Mauro de la tierr is a child prodege raised by space pirates now spending his time as an illustrator, painter, director and sculptor from the East side of San Antonio.',

    Row: 'one',
    Age: '1312',
    File: 'mauro.jpg',
    Portrait: 'mauro.jpg',
    Weight: '1 emperor penguin',
    Alt: 'portrait of Mauro de la Tierra',
    Id: 'mauro'
  },
  {
    Name: 'Joshua Anthony Rodriguez',
    Age: "still gets ID'd at liquor stores",
    Description: 'The form known as "Joshua Anthony Rodriguez" was an amoeba self-birthed in a rogue chronosynclastic infundibulum, manifesting itself as a teenage skeleton until reaching full, ripe maturity as a 5th-dimensional space eel. It also writes comics in its free time. ',
    Weight: '1000 Keurig pods',
    Row: 'one',
    File: 'josh.jpg',
    Portrait: 'josh.jpg',
    Alt: 'portriat of J Rodriquez',
    Id: 'josh'
  },

]

export default characterInfo;