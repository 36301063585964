const products = [

  {
    File: 'JFK_brain.webp',
    Name: 'JFK brain',
    Description: 'Scraped off the streets of Dallas. Brain not functional.',
    Price: '789.56',
  },
  {
    File: 'product_UGLY.webp',
    Name: 'THE BOOK OF THE DEAD',
    Description: 'MADE WITH REAL HUMAN SKIN.',
    Price: '666.00',
  },
  {
    File: 'blkshp.webp',
    Name: 'Black Sheep',
    Description: 'Pawned it for the third time this year.',
    Price: '66.6',
  },
  {
    File: 'Elora_01.webp',
    Name: 'Rat Person',
    Description: 'We are pretty sure there\'s a person in there.',
    Price: '550.00',
  },
  {
    File: 'product_BMO.webp',
    Name: 'BMO B-BOY',
    Description: 'Real life break dancing game boy!',
    Price: '3338.2',
  },

]

export default products;