var products = [
  {
    File: 'product_STRO_6789.webp',
    Name: 'Haunted Stereo',
    Description: 'Automatically chops and screws everything.',
    Price: '281.00'
  },

  {
    File: 'Yoda_hilt.webp',
    Name: 'Lightsaber',
    Description: 'Pawned by Master Yoda. Times were tough during the Clone Wars... All for a hit of insulin.',
    Price: '189.00'
  },
  {
    File: 'product_XBOX.webp',
    Name: 'X-Box 360',
    Description: 'Does not work due to the red ring of death. Comes with copy of Top Spin.',
    Price: '694.20'
  },
  {
    File: 'oscar.webp',
    Name: 'Mickey’s oscar',
    Description: 'You can easily scratch the name off and add a tag of your choosing.',
    Price: '407.90'
  },
  {
    File: 'Holy_grail.webp',
    Name: 'The Holy Grail',
    Description: 'Straight from malt liquor heaven.',
    Price: '2222.67',
  },
  {
    File: 'product_RED_Guitar.webp',
    Name: 'Roxy',
    Description: 'Real guitar stolen from legendary musician The Wizard. Signed in bink.',
    Price: '856.67',
  },
  {
    File: 'Bullpup_870.webp',
    Name: 'Watergun',
    Description: 'Good for spraying netbangers.',
    Price: '567.89',
  },


];


export default products;

